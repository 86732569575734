*{margin: 0px; padding: 0px;}
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{font-family: "Lato", sans-serif; font-size: 14px; color: rgba(0,0,0,0.8); overflow-x: hidden !important; font-size: 15px;}

.bg-blue{ background:#001f3f !important;}
.colorbtnmy{
  background: linear-gradient(to bottom, #3a6d8c, #6a9ab0) !important;
  color: white !important;
  transition: background 0.3s ease;


  cursor: pointer;
  overflow: hidden;
  transition: 0.2s;
}
.colorbtnmy:hover{
  background: linear-gradient(to bottom, #142b3a, #143c4e) !important;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.5);

}

.marginlow{
  margin-top: -24px !important;
}

.marquee {
  display: inline-block;
  animation: marquee 15s linear infinite;
}
@keyframes marquee {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}




 .white{
  color: black !important;
 }
 .changeposition{
  color: white !important;
  border-radius: 50px !important;
  margin-top: 30px;
 }
 .colorbtn{
  background: #000 !important;
  color: white !important;
 }
 .colorbtn svg{
  font-size: 25px !important;
  font-weight: bolder !important;
 }
 .categorySection{
  margin-top: 50px;
 }
@media (min-width: 1300px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1280px;
}
}
.words{
  word-spacing: 15px !important;
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root{z-index: 1000000 !important;}
.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-elevation{max-height: 350px !important;}

button .MuiCircularProgress-root{width: 30px !important; height: 30px !important;}
  button svg{color: #fff;}

.badge{display: inline-block; padding: 7px 10px; border-radius: 20px;}
.badge.bg-success{background: #e5f8ed !important; color: #16b858 !important;}
.badge-success{color: #00b853; background: #e5f8ed;}

button{text-transform: capitalize !important;}

.btn-blue{
  background: linear-gradient(to bottom, #3a6d8c, #6a9ab0) !important;
   color: #fff !important; 
  text-transform: capitalize !important; font-weight: 600 !important; padding: 5px 20px !important;}
 

 .thankudialogeafterordersubmit{
  display: flex;
  align-items: center;
  justify-content: space-between;
 }
 .thankuh1{
  padding: 10px;
  text-align: center;
  font-weight: 700;
 }
.bg-red{background: linear-gradient(to bottom, #3a6d8c, #6a9ab0) !important}

.text-red{color: #142b3a !important; font-weight: bolder;}
.text-blue{color: #53348c !important;}

  /* .btn-blue:hover{background: #f7f1c8 !important;}  */
.btn-big{font-size: 15px !important; padding: 8px 30px !important; min-width: auto !important;
font-weight: bold !important;}
.btn-round{border-radius: 20px !important;}

.btn-blue svg{font-size: 22px;}

.btn-sml{font-size: 12px !important; color: #000; text-transform: capitalize !important;}
.btn-sml svg{font-size: 20px;}

.btn-circle{border-radius: 50% !important; min-width: auto !important;}

.cursor{cursor: pointer;}
.text-light{color: #8b8b8b !important;}
.text-sml{font-size: 14px;}

::-webkit-scrollbar{width: 8px !important; height: 8px !important;}
::-webkit-scrollbar-thumb{background: #ccc !important;}
::-webkit-scrollbar-track{background: transparent !important;}
.newsLetterSection h6{
  color: white;
}
.headerWrapper{border-bottom: 1px solid rgba(0,0,0,0.1); background: #fff;}
.headerWrapperFixed{background: #fff; transition: all 0.3s ease-in-out; width:100%; z-index: 10000; }
.headerWrapperFixed.fixed{top:-123px; box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 12%);}
.MuiDialog-root {z-index: 100000 !important;}

.headerWrapperFixed + div{margin-top: 50px !important;}
.headerWrapperFixed + section{margin-top: 150px ;}
.none-display{
  display: none !important;
}
.iiz__img{
  height: 170px !important;
}
tr{
  width: 100% !important;
}
.headerWrapper .top-strip{padding: 8px 0px; width: auto; height: 35px !important; text-wrap: nowrap !important; }
.headerWrapper .top-strip p{color: #fff; font-size: 12px; font-weight: 500;}

.headerWrapper .header{width: 100%; height: auto; padding: 10px 0px;}

.logoWrapper img{ width: 125%; }

.part3{
  width: 18% ;
}

.rowheadermain{
  justify-content: space-between;
}
.countryDrop{width: 170px; height: 50px; min-width: 170px; color: #000 !important; border: 1px solid rgba(0,0,0,0.1) !important; 
  border-radius: 7px !important; padding: 10px 15px !important;}
.countryDrop:hover{box-shadow: 0 3px 5px #9ba4b4;}
  
.countryDrop{text-align: left; justify-content: flex-start !important;}
.countryDrop .label{font-size: 12px !important; text-transform: capitalize !important; color: rgba(0,0,0,0.4) !important; line-height: 16px;}
.countryDrop .name{font-size: 14px !important; text-transform: capitalize !important; color: #14274e !important; font-weight: 700;  line-height: 16px;}

.countryDrop span.ml-auto svg{opacity: 0.7;}

.close{opacity: 1 !important;}
.closeSearch{display: none;}

.mobilesearchbox{width: 70%;}
.headerSearch{margin-left: 1rem !important;
  margin-right: 1rem !important
  ;
  width: 90%; height: 50px; background-color: #f1f6f9; padding: 5px; position: relative; border-radius: 5px; border: 1px solid rgba(0,0,0,0.050);} 
.headerSearch input{background: white !important; outline: none !important;
font-size: 16px; color: rgba(0,0,0,0.7); width: 100%; height: 37px; border: 0px !important;
padding: 0px 20px !important;}

.headerSearch input::-webkit-input-placeholder{color: rgba(0,0,0,0.4);}

.headerSearch  button{position: absolute; top: 5px; right: 10px; z-index: 100; min-width: 40px !important; height: 40px !important; width: 40px !important; border-radius: 50% !important; color: #000;}
.headerSearch  button svg,header .part3 .circle svg{font-size: 25px; opacity: 0.8; color: #000 !important;}

.headerSearch  button .MuiCircularProgress-root{width: 25px !important; height: 25px !important;}

header  .part3 .circle{align-items: center; justify-content: center; min-width: 45px !important; height: 45px !important; width: 45px !important; border-radius: 50% !important; border: 1px solid rgba(0,0,0,0.1) !important; color: #000;     background-color: #f1f6f9 !important;
}
header .startmenu .circle{
  align-items: center; justify-content: center; min-width: 45px !important; height: 45px !important; width: 45px !important;   color: #000;  
}
.startmenu .circle svg{
  font-size: 22px;
  opacity: 0.8; color: #000 !important;
}
header  .part3 .circle svg{font-size: 22px;}

#accDrop{z-index: 10000 !important;}
#accDrop ul a{color: #000;}
#accDrop ul li{font-size: 16px !important; padding: 7px 15px !important;}
#accDrop ul li svg{font-size: 15px !important;}

.MuiDialog-container .MuiPaper-root{max-height: calc(100% - 25px);}
.cartTab{
  width: 80%;
}
.cartTab .price{ padding-right: 13px; font-size: 16px; color: rgba(0,0,0,0.8); font-weight: 700; margin-right: 5px;}
.cartTab button{background: #faf8de !important;}
.cartTab button svg{color: #ea2b0f !important;}
.cartTab .count{position: absolute; top: -6px; right: -4px; background: #14274e; border-radius: 50%; color: #fff; width: 20px; height: 20px;}

.slick-dots li button:focus, .slick-dots li button:hover{outline: none !important;}
.slick-dots li button:before{opacity: 0.5 !important;}
.slick-dots li.slick-active  button:before{opacity: 1 !important;}
.slick-dots li button:before{font-size: 8px !important;}

.locationModal .MuiPaper-elevation{ padding: 25px; position: relative;}
.locationModal .MuiPaper-elevation h4{font-size: 18px; font-weight: 600;}
.locationModal .MuiPaper-elevation p{font-size: 13px;}

.locationModal .countryList{ margin-bottom: 0px; max-height: 400px; overflow-y: scroll; overflow-x: hidden;}
.locationModal .countryList li{width: 100%; list-style: none;}
.locationModal .countryList li button{width: 100%; text-transform: capitalize !important;
color: #000 !important; justify-content: flex-start !important; padding: 10px 20px !important;}

.locationModal .countryList li button:hover{background: #9ba4b4;}
.locationModal .countryList li button.active{background: #14274e !important; color: #fff !important;}

.close_{position: absolute !important; top: 10px; right: 10px; color: #000 !important; min-width: 40px !important; width: 40px !important; height: 40px !important; border-radius: 50% !important; background: #f1f1f1 !important;}

.close_ svg{font-size: 22px; color: #000 !important; opacity: 0.7;}


nav .navPart1{max-width: 20%; flex: 0 0 20%;}
nav .navPart2{max-width: 80%; flex: 0 0 80%;}

nav .navPart1 .catWrapper{position: relative;}
nav .navPart1 .catWrapper .sidebarNav{width: 100%; height: 0px; opacity: 0; background-color: #f1f6f9; position: absolute; top: 130%; left: 0px; border: 1px solid rgba(0,0,0,0.4);  padding: 15px 0px; transition: all 0.2s; visibility: hidden; z-index: 100; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;}

nav .navPart1 .catWrapper .sidebarNav.open{height: auto; opacity: 1; visibility: inherit;}

nav .navPart1 .catWrapper .sidebarNav ul li{width: 100%; list-style: none; padding: 0px 10px; border-bottom: 1px solid #14274e}
nav .navPart1 .catWrapper .sidebarNav ul li button{ text-align: left !important; color: #000000; width: 100%; justify-content: flex-start; text-transform: capitalize !important;  }

nav .navPart1 .catWrapper .sidebarNav ul li button svg{font-size: 12px; opacity: 0.6; color: black !important;}

/* nav .navPart1 .catWrapper .sidebarNav ul li button:hover{background: transparent !important; color: #7a55c1 !important;} */

/* nav .navPart1 .catWrapper .sidebarNav ul li:hover button:first-child{color: #7a55c1 !important;} */

nav .navPart1 .catWrapper .sidebarNav ul li{position: static;}

nav .navPart1 .catWrapper .sidebarNav ul li .submenu{position: absolute; top: 0px; left: 105%;
width: 250px; height: 100%; background: #fff; z-index: 100; opacity: 0; visibility: hidden;
transition: all 0.2s ease-in-out; border-left: 1px solid rgba(0,0,0,0.1); padding: 20px;}

nav .navPart1 .catWrapper .sidebarNav ul li:hover .submenu{opacity: 1; visibility: inherit; left: 100%;}

nav .navPart1 .catWrapper .sidebarNav ul li:hover .submenu button{border-radius: 0px !important; color: #000 !important;}
nav .navPart1 .catWrapper .sidebarNav ul li:hover{
  background: #9ba4b4;
}
nav .navPart1 .catWrapper .sidebarNav ul li:hover button{
  color: black !important;
}

nav .navPart1 .catWrapper .sidebarNav ul li .submenu button:hover{color: #000000 !important;}

nav .allCatTab{background: #14274e;  border-radius: 30px !important;}
nav .allCatTab *{color: #fff;}
nav .allCatTab:hover{background: #9ba4b4;}
nav .allCatTab .text{font-weight: 600;}

nav .allCatTab .icon1 svg{font-size: 20px !important;}
nav .allCatTab .icon2 svg{font-size: 15px !important;}

nav .navPart2 ul{margin-bottom: 0px;}
nav .navPart2 ul li{padding-bottom: 20px; margin-right: 0px !important;}
nav .navPart2 ul li a{text-decoration: none;}
nav .navPart2 ul li a button{ font-size: 14px !important; font-weight: 600; text-decoration: none; color: rgba(0,0,0,0.7); text-transform:uppercase !important; display: flex; align-items: center; padding: 6px 20px; transition: all 0.2s ease-in-out; border-radius: 25px;}
nav .navPart2 ul li a button:hover{background: #9ba4b4;}

nav .navPart2 ul li:hover a button{background: #9ba4b4}

nav .navPart2 ul li a svg{font-size: 20px;}

nav .navPart2 ul li a:hover{background: #9ba4b4; color: #000000;}

nav .navPart2 ul li{position: relative;}
nav .navPart2 ul li .submenu{position: absolute; top: 150%; left: 0px; min-width: 200px;
background: #fff; opacity: 0; visibility: hidden; transition: all 0.2s ease-in-out; z-index: 100; overflow: hidden; border-bottom-left-radius: 10px;  border-bottom-right-radius: 10px;
box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);}
nav .navPart2 ul li .submenu a{display: block; width: 100%;}
nav .navPart2 ul li .submenu a button{ text-align: left !important; width: 100%; justify-content: flex-start !important;
border-radius: 0px !important; text-transform: capitalize !important; font-weight: 500 !important; color: #282828 !important; background: #fff;}

nav .navPart2 ul li .submenu a button:hover{background: #9ba4b4 !important;}

nav .navPart2 ul li:hover .submenu{opacity: 1; visibility: inherit; top: 100%;}

.bannerSection{
  width: 200px !important;
}
.bannerAds {
  display: flex;
  justify-content: start;
  background-color: red;
}


/* .category-swiper{
  opacity: 1 !important;
  transform: scale(1) !important ; 

}
*/
.category-swiper .swiper-slide{

width: 80px !important;
} 
.homeBannerSection img{
  width: 100%;
  height: 300px !important;
}
.homeBannerSection{position: relative; overflow: visible !important;}
.homeBannerSection .swiper{overflow: visible !important; width: 100% !important;}
.homeBannerSection .swiper .item{overflow: visible; border-radius: 10px;}
.homeBannerSection *{outline: none !important;}
.homeBannerSection .swiper .swiper-slide-active .item img{-webkit-animation: zooming 14s ease-in-out infinite alternate; animation: zooming 14s ease-in-out infinite alternate;}
.swiper-button-next{right: 3% !important; position: absolute; top: 45%; z-index: 100; display: flex; align-items: center; justify-content: center; width: 45px !important; height: 45px !important; background: #fff; border-radius: 100%; font-family: FontAwesome !important; font-weight: bold; box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;}
.swiper-button-prev{left: 3% !important;
  position: absolute; top: 45%; z-index: 100; display: flex; align-items: center; justify-content: center; width: 45px !important; height: 45px !important; background: #fff; border-radius: 100%; font-family: FontAwesome !important; font-weight: bold; box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
}
.swiper-button-prev:after, .swiper-button-next:after {
  color: #000 !important;
  opacity: 0.8;
  font-size: 17px !important;
}

  /* Pagination Dots */
  .swiper-pagination-bullet {
    background: black !important;
    opacity: 0.5 !important;
  }
  
  .swiper-pagination-bullet-active {
    background: orange !important;
    opacity: 1 !important;
  }

.swiper-button-prev:hover, .swiper-button-next:hover {background: #9ba4b4 !important;}

.homeBannerSection {cursor: pointer;}


.homeProducts{padding: 25px 0px; width: 100%;}
.homeProducts .banner{width: 100%; border-radius: 7px; overflow: hidden;}

.homeProducts .col-md-3{max-width: 22%; flex: 0 0 22%;}
.homeProducts .col-md-9{max-width: 78%; flex: 0 0 78%;}

.hd{ font-size: 22px; font-weight: 700; text-transform: uppercase; 
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}


.viewAllBtn{padding: 5px 15px !important; background: #fff; border: 1px solid rgba(0,0,0,0.1) !important; border-radius: 30px !important; color: #414141 !important; font-weight: 600; text-transform: capitalize !important;}

.viewAllBtn svg{font-size: 20px; color: rgba(0,0,0,0.8) !important;}
.viewAllBtn:hover{
  color: #000;
  background: #9ba4b4 !important;
}

.productItem{width: 100%; height: auto; border: 1px solid rgba(0,0,0,0.1); cursor: pointer; position: relative; transition: all 0.2s; background: #f1f6f9; border-radius: 10px !important;
  text-decoration: none; color:#000; display: block; overflow: hidden;}

.productItem:hover{text-decoration: none;}
.productItem:hover{box-shadow: 0 3px 6px rgba(123, 108, 108, 0.16), 0 3px 6px rgba(0,0,0,0.23);
  border: 1px solid transparent !important;}

.productItem .badge{ display: inline-block; padding: 7px 10px; position: absolute; top: 10px; left: 10px; z-index: 100;}

.productItem .info{padding: 15px;}
.productItem .info h4{font-size: 15px; font-weight: 600; color: #2e2e2e !important; transition: all 0.2s ease-in-out;}
.productItem .info h4:hover{color: #ed174a   !important;}
.productItem a:hover{text-decoration: none;}

.info .oldPrice{font-size: 16px !important; font-weight: bold; color: rgba(0,0,0,0.4);
text-decoration: line-through;}
.info .oldPrice.lg{font-size: 20px;}
.info .netPrice{font-size: 16px !important; font-weight: bold; }
.info .netPrice.lg{font-size: 20px;}

.productItem .actions{position: absolute; width: 50px; height: 100%; top: 20px; right: 0%; z-index: 1000; display: flex; flex-direction: column; gap:10px; transition: all 0.5s ease-in-out; opacity: 0;}
.productItem .actions button{display: flex; align-items: center; justify-content: center; min-width: 40px !important; height: 40px !important; width: 40px !important; border: 1px solid rgba(0,0,0,0.1) !important; border-radius: 50% !important;  background: linear-gradient(to bottom, #1e3646, #6a9ab0) !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important; transition: all 0.0s !important;}
.btncahnge svg{
  margin-right: 10px !important;
  color: white !important;

}
  .productItem .actions button svg{color: #ffffff !important; opacity: 0.8; pointer-events: none;
  font-size: 15px;}

  .productItem .actions button:hover{ background: linear-gradient(to bottom, #142b3a, #143c4e) !important;
    }
  .productItem .actions button:hover svg{color: #ffffff !important; opacity: 1 !important;}

  .productItem .actions button.active{background: #142b3a  !important; }

  .productItem .actions button.active svg{color: #fff !important; opacity: 1 !important;}

  .productItem .img_rapper{overflow: hidden; position: relative; width: 100%;  border-radius: 7px; border-bottom-left-radius: 0px;  border-bottom-right-radius: 0px; background: #fff; }
  .productItem .img_rapper a{display: block; position: relative; width: 100%; height: 100%;}
  .productItem .img_rapper .slick-slider{padding: 0px !important; width: 100% !important; height: 220px; overflow: hidden;}
  .productItem .img_rapper .slick-slider .slick-slide{overflow: hidden !important; padding: 0px !important; height: 220px;}

  .productItem .img_rapper .swiper-wrapper{padding: 0px !important;}
  .productItem .img_rapper .swiper .swiper-slide{padding: 0px !important; width: 100% !important; height: 220px;}


  .productItem .img_rapper .slick-dots{bottom: 0px !important;  padding: 15px 0px; z-index: 100;}
  .productItem .img_rapper .slick-dots li{width: 7px !important;}
  .productItem .img_rapper .slick-dots li button{opacity: 1;}

  .productItem .img_rapper .slick-dots li button:before{color: #fff !important;}


  .productItem .img_rapper img{transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; height: auto; }
  .productItem:hover .actions{opacity: 1; }
  .productItem:hover .img_rapper img{transform: scale(1.1) !important;}

  .productItem .img_rapper .MuiSkeleton-root{width: 100% !important; height: 230px !important;
  position: relative; z-index: 1000 !important; display: flex; align-items: center; justify-content: center;}
  .productItem .img_rapper .MuiSkeleton-root svg{font-size: 70px !important; visibility: inherit !important; color: #000 !important; opacity: 0.1 !important;}

  
  .productItem:hover .btn-blue.w-100{opacity: 1;}

.productItemSliderWrapper{position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 100; transition: all 0.5s; overflow: hidden;  opacity: 0;}
.productItem:hover .productItemSliderWrapper{opacity: 1; }
.productItemSliderWrapper .productItemSlider{position: relative;}
.productItemSliderWrapper::after{content: ''; width: 100%; height: 100%; z-index: 10000; position: absolute; bottom: 0px; left: 0px; opacity: 0.1; pointer-events: none; background:#000; opacity: 0.2;}

  .product_row{margin-left: -10px; margin-right: -10px;}
  .product_row .swiper{padding-right: 80px !important;}
.product_row .productItem{border-right: 1px solid transparent !important;}
.product_row .swiper-slide:last-child .productItem{ border: 1px solid rgba(0,0,0,0.1) !important;}

.product_row .swiper-wrapper{display: flex; gap: 15px; padding: 5px 10px;}

.product_row .swiper-slide{padding: 15px 0px !important;}

.product_row .swiper-button-prev{left: 10px !important;}
.product_row .swiper-button-next{right: 10px !important;}

.swiper{padding-right: 2px !important;}

.badge-primary{background: #2bbef9 !important;}

.homeCat .swiper{overflow: inherit !important;}
.homeCat .swiper .item{padding:0px; width:70px; height: 70px; border-radius: 100%; border: 2px solid lightblue ; 
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
transition:all 0.2s ease-in-out; border: 1px solid rgba(0,0,0,0.1); overflow: hidden;
margin: auto; display: flex; align-items: center; justify-content: center;}
.homeCat .swiper .item:hover img{ -webkit-animation:mover .7s infinite alternate;animation:mover .7s infinite alternate;}
.homeCat .swiper .item img{width: 90%; background-size: cover;
height: 90%; 
border-radius: 50%;
}
.homeCat .swiper .swiper-slide h6{font-weight: 600; font-size: 16px; color: #000; opacity: 0.8; text-transform: capitalize;  width: 100%; text-align: center; padding-top: 10px;}


@-webkit-keyframes mover{0%{transform:translateY(0)}100%{transform:translateY(-4px)}}
@keyframes mover{0%{transform:translateY(0)}100%{transform:translateY(-4px)}}

.homeCat .swiper .item:hover{box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); transform: translateY(-5px); background: #ccc;}

.homeCat .swiper-button-prev, .homeCat .swiper-button-next{top: -30px !important; width: 30px !important; height: 30px !important;}
.homeCat .swiper-button-prev{right: 30px !important; left: inherit !important;}

.homeCat .swiper-button-next{right: -10px !important;}

.homeCat .swiper-button-prev::after, .homeCat .swiper-button-next::after{font-size: 12px !important;}



.productRow2{flex-wrap: wrap; gap: 15px;}
.productRow2 .productItem{width: 23.5%; overflow: hidden; border-right: 1px solid rgba(0,0,0,0.1) !important;}


.bannerSec{gap: 15px;}

.sticky{position: sticky; top: 85px; z-index: 100;}

.newsLetterSection{width: 100%; height: auto  ; 
  background-image: linear-gradient(to bottom, #130e3d, #202146) !important ;

    position: relative;
overflow: hidden; padding: 60px 0px;}
.newsLetterSection .col-md-6{position: static !important;}
.newsLetterSection p{font-size: 18px; margin: 10px 0px !important;}
.newsLetterSection h3{font-size: 40px; font-weight: bold;}
.newsLetterSection p.text-light{color: #fff !important; opacity: 0.7; font-size: 15px;}
.newsLetterSection img{position: absolute; bottom: 0px; right: 0px; width: 500px;}

.newsLetterSection form{width: 80%; height: 60px; background: #fff; padding: 10px; position: relative; border-radius: 5px;}
.newsLetterSection form svg{position: absolute; top: 17px; left: 10px; font-size: 25px; opacity: 0.3;}
.newsLetterSection form input{width: 100%; height: 100%; background: #fff !important; outline: none !important; border: 0px !important; padding-left: 40px; padding-right: 200px;}

.newsLetterSection form button{position: absolute; top: 6px; right: 7px;
   /* background:#6d4aae !important ; font-size: 14px !important; */
  background-image: #14274e !important;

    padding: 12px 25px !important; color: #fff !important; text-transform: capitalize !important; font-weight: 600 !important;}


footer{width: 100%; height: 350px; margin: auto; background: #fff; padding: 25px 0px;}
footer .topInfo{border-bottom: 1px solid rgba(0,0,0,0.1); padding-bottom: 20px;}
footer .topInfo .col{padding: 10px 15px; padding-left: 40px; border-right: 1px solid rgba(0,0,0,0.2);}
footer .topInfo .col:last-child{border-right: 0px;}
footer .topInfo .col svg{font-size: 26px;}
footer .topInfo .col span{font-size: 14px; font-weight: 600;}


footer .linksWrap h5{font-size: 16px; margin-bottom: 20px; font-weight: bolder; color: #14274e !important;}
footer .linksWrap ul li{list-style: none; margin-bottom: 10px;}
footer .linksWrap ul li a{color: #4e556e; font-size: 13px;}
footer .linksWrap ul li a:hover{text-decoration: none; color: #000;}

.socials li a{display: flex; align-items: center; justify-content: center; width: 35px; height: 35px; border-radius: 50%; border: 1px solid rgba(0,0,0,0.2); 
 }
 .socials li a svg{font-size: 16px; color: #14274e;}
 .socials li a:hover{background: #9ba4b4 !important;}
 .socials li a:hover svg{color: #000 !important;}

 .productModal .actions button{opacity:  1 !important;}
 .productModal .actions button svg.text-danger{color: #ed174a  !important; opacity: 1 !important;}

.productModal .MuiPaper-elevation{ padding: 25px; position: relative; max-width: 900px !important; width: 900px !important;}

.productModal h4{font-size: 20px !important; color: rgba(0,0,0,0.8) !important;}


.productZoom{ width: 100%; height: auto;  padding: 0px; overflow: hidden; border-radius: 15px;}
.productZoom .badge{position: absolute; top: 10px; left: 10px; z-index: 100;}
.zoomSliderBig{width: 100% !important;}
.productZoomBig{height: auto; overflow: hidden;}
.imgperfectsize{
  width: 569px !important;
  height: 569px !important;
}

/* .productDetails .productZoomBig{height: 350px !important;} */

.zoomSlider{padding-top: 30px;}
.zoomSlider *{outline: none !important;}
.zoomSlider .item{border: 2px solid transparent !important; overflow: hidden; border-radius: 8px; cursor: pointer;}
.zoomSlider .item_active{border: 1px solid rgba(0,0,0,0.5) !important;  }
.zoomSlider .item img{object-fit:cover;}
.zoomSlider .swiper-button-prev{width: 30px !important; height: 30px !important; top: 60% !important; left: -3% !important;}
.zoomSlider .swiper-button-next{width: 30px !important; height: 30px !important; top: 60% !important; right: -3% !important;}

.zoomSlider .swiper-slide .item{opacity: 0.5;}
.zoomSlider .swiper-slide .item_active{opacity: 1 !important;}

.quantityDrop{width: 150px; gap: 10px;}
.quantityDrop button{min-width: 40px !important; width: 40px !important; height: 40px !important; border-radius: 50% !important; background: #edeef5 !important; border: 1px solid rgba(0,0,0,0.1) !important; color: #000;}
.quantityDrop button svg{color: #000 !important;}

.quantityDrop button:hover{background: #9ba4b4 !important;}

.quantityDrop input{width: 30px; border: 0px; background:transparent; outline: none !important;
text-align: center;}


.productModal .actions button{color: #ffffff !important; border: 1px solid rgba(0,0,0,0.1) !important; font-size: 10px !important; }
.productModal .actions button svg{font-size: 15px !important; color: #ffffff !important;}
.productModal .actions button:hover{background: #9ba4b4; opacity: 1 !important;}

.productDetaileModal p{font-size: 14px; line-height: 25px; opacity: 0.8;}

.product_Listing_Page{padding: 25px 0px;}
.product_Listing_Page .productListing{gap: 20px;}
.product_Listing_Page .productListing .sidebar{width: 20%; flex: 0 0 20%;}
/* .product_Listing_Page .productListing .sidebar .sticky{position: sticky; top: 20px;} */


.product_Listing_Page .productListing .sidebar .filterBox{margin-bottom: 35px;}
.product_Listing_Page .productListing .sidebar .filterBox h6{font-weight: 700; font-size: 14px;
margin-bottom: 15px;}

.product_Listing_Page .productListing .sidebar .scroll{padding-left: 10px; max-height: 180px; overflow-y: scroll; overflow-x: hidden;}
.product_Listing_Page .productListing .sidebar .scroll::-webkit-scrollbar{width: 5px !important; height: 5px !important;}
.product_Listing_Page .productListing .sidebar .scroll ul{margin-bottom: 0px;}
.product_Listing_Page .productListing .sidebar .scroll ul li{list-style: none; margin-bottom: 0px; width: 100%;}
.product_Listing_Page .productListing .sidebar .scroll ul li label{margin-bottom: 0px !important;}
.product_Listing_Page .productListing .sidebar .scroll ul li *{text-transform: capitalize !important;}

.product_Listing_Page .productListing .sidebar .scroll label{margin-bottom: 0px !important;}

.MuiRadio-root{zoom: 85% !important;}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{padding: 2px !important;}
.css-ahj2mt-MuiTypography-root{font-size: 13px !important;}
.css-i4bv87-MuiSvgIcon-root{width: 20px !important; height: 20px !important;}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{color: #14274e !important;}

.range-slider {height: 5px !important;}
.range-slider .range-slider__thumb,.range-slider .range-slider__range{background: #14274e !important;}

.range-slider .range-slider__thumb{width: 15px !important; height: 15px !important;}



.loading{width: 100%; height: 300px;}

.product_Listing_Page .productListing .content_right{width: 78%; flex: 0 0 78%;
  padding-left: 50px;}

.product_Listing_Page .productListing .content_right .showBy{width: 100%; height: auto; background: #142b3a; padding: 15px 25px;}
  
.product_Listing_Page .productListing .content_right .showBy .btnWrapper button{min-width: 35px !important; height: 35px !important; width: 35px !important; border-radius: 50% !important;
color: #000;}
.product_Listing_Page .productListing .content_right .showBy .btnWrapper button svg{color: #ffffff; font-size: 18px !important; opacity: 0.3;}

.product_Listing_Page .productListing .content_right .showBy .btnWrapper button.act svg{opacity: 1 !important;}

.product_Listing_Page .productListing .content_right .showBy .btnWrapper button:hover svg{opacity: 1;}

.showByFilter button{min-width: auto !important; color: #000;}
.showByFilter button svg{color: #000; opacity: 0.6; margin-left: 5px;}
.showByFilter button.act svg{opacity: 1 !important;}


.showPerPageDropdown .MuiPaper-root{width: 80px !important;}

.productListing,.infinite-scroll-component {display: flex; gap: 20px; flex-wrap: wrap;}
.productListing .productItem.four{width: 23%;}
.productListing .productItem.three{width: 31.5%;}
.productListing .productItem.two{width: 48%;}
.productListing .productItem.one{width: 100%; display: flex; align-items: center;}
.productListing .productItem.one .img_rapper{width: 25%;}

/* .productListing .productItem.three .img_rapper,.productListing .productItem.three .img_rapper .slick-slider,.productItem.three .img_rapper .slick-slider .slick-slide{height: 325px;} */


.section{padding: 45px 0px;}

.productDetails{padding-bottom: 0px ;}
.productDetails h2.hd{font-size: 26px; line-height: 38px;}
.productDetails h3.hd{font-size: 18px !important;}

.productDetails .oldPrice{font-size: 20px;}
.productDetails .netPrice{font-size: 22px;}

.reviewBox{border-bottom: 1px solid rgba(0,0,0,0.2) !important;}

.productSize{width: 100%; height: auto; padding: 7px 0px;}
.productSize ul li{margin-right: 10px !important;}
.productSize ul li a{display: flex; align-items: center; justify-content: center; padding:5px 10px !important; border: 1px solid rgba(0,0,0,0.1); cursor: pointer; text-decoration: none !important; color: #000; font-size: 14px !important; transition: all 0.1s ease-in-out; border-radius: 4px !important; }
.productSize ul li a:hover{background:#9ba4b4;}
.productSize ul li a.active{background: #14274e!important; color: #fff !important;}
.productSize ul.error li a{border: 1px solid red !important;}

.productDetails .btn-circle{
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    background: #edeef5 !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    color: #000;
    padding: 5px !important;
}



.productDetails .btn-circle svg{color: #000 !important; opacity: 0.8; font-size: 18px;}
.productDetails .btn-circle:hover{background: #9ba4b4 !important;}

.productDetails .btn-circle svg.text-danger{color: #ed174a  !important; opacity: 1 !important;}


.detailsPageTabs{border-radius: 25px !important; background-color: #f1f6f9;}

  .customTabs ul li{margin-right: 10px !important;}
  .customTabs ul li button{padding: 6px 25px !important; border: 1px solid rgba(0,0,0,0.2) !important; font-size: 14px !important; text-transform: capitalize !important; border-radius: 30px !important; color: #000 !important;}

  .customTabs ul li button.active{
    background-color: #14274e !important;

     color: #fff !important;}

  .reviewsCard{margin-bottom: 25px !important; border-radius: 10px !important;}

  .reviewsCard .image{width: 100px;}
  .reviewsCard .rounded-circle{display: flex; align-items: center; justify-content: center; overflow: hidden; width: 100px; height:100px;}
  .reviewsCard .rounded-circle img{width: 100%; height: 100%; object-fit: cover;}
  .reviewsCard  .info{width: 85%;}

  .detailsPageTabs .table th,  .detailsPageTabs .table td{ vertical-align: middle; font-size: 14px;}

  .detailsPageTabs .table td p{margin-bottom: 0px;}

  .detailsPageTabs .tabContent h3{font-size: 20px; font-weight: 700;}

  .reviewForm h4{font-size: 20px; margin-bottom: 15px; font-weight: 700;}
  .reviewForm .form-group{margin-bottom: 25px;}
  .reviewForm .form-group .form-control{height: 50px; font-size: 16px; border-radius: 7px !important; padding-left: 20px; outline: none !important;}
  .reviewForm .form-group textarea.form-control{height: 200px; padding-top: 25px;}

  .progressBarBox{margin-bottom: 10px;}
  .progress-bar{font-size: 16px;}

  .reviewsCard .info p{font-size: 13px; line-height: 22px;}


  .cartPage .table thead{background: #e3e3e3 !important;overflow: hidden;}
  .cartPage .table thead th{border: 0px !important;}
  .cartPage .table thead th:first-child{border-top-left-radius: 10px !important; 
    border-bottom-left-radius: 10px !important;}
    .cartPage .table thead th:last-child{border-top-right-radius: 10px !important; 
      border-bottom-right-radius: 10px !important;}



  a:hover{text-decoration: none;}

  

  .cartItemimgWrapper{width: 280px;}
  .cartItemimgWrapper .imgWrapper{width: 30%; height: 80px; overflow: hidden;}  
  .cartItemimgWrapper .imgWrapper img{width: 100%; height: 100%; object-fit: cover;} 
  .cartItemimgWrapper  .info{width: 80%;}
  .cartItemimgWrapper  .info a:hover{text-decoration: none;}
  .cartItemimgWrapper  .info h6{color: #343434; font-weight: bold;}
  .cartItemimgWrapper  .info h6:hover{color: #ed174a   !important;}
  .remove{cursor: pointer;}
  .remove svg{font-size: 30px;}
  .remove:hover svg{color: #ed174a   !important;}

  .cartPage .quantityDrop button{zoom: 75%;}
  .cartPage table th,  .cartPage table td{vertical-align: middle;}

  .cartPage .table td,   .cartPage .table th{border-top: 0px !important;  border-bottom: 1px solid rgba(0,0,0,0.1) !important;}

  .cartPage table tbody tr:hover{background: #9ba4b4;}

  .cartDetails{ border-radius: 7px !important; position: sticky;
    top: 20px; 
    background: linear-gradient(to bottom, #6a9ab0, #3a6d8c) !important;
    color: white !important;
  }
  .cartDetails h4{font-size: 16px; padding-bottom: 15px; font-weight: 800 !important; border-bottom: 1px solid rgba(0,0,0,0.1); color: white !important;}

.relatedProducts .swiper{overflow: inherit !important;}


.signInPage{width: 100%; height: 100vh; position: relative; overflow: hidden;}
.signInPage .shape-bottom {
  position: absolute;
  top: auto;
  bottom: -6px;
  left: 0;
  right: 0;
  z-index: 1;
}

.signInPage::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  /* background-image: linear-gradient(#46277e, #583991); */
  /* background-image: linear-gradient(#084d0a, #084d0a); */
  background-image:linear-gradient(to bottom, #3a6d8c, #6a9ab0) !important;

  opacity: 0.9;
  z-index: -1;
}


.border-effect{position:relative;color:#337ab7;text-decoration:none}.border-effect:after{display:block;position:absolute;left:0;bottom:-2px;width:0;height:1px;background-color:#102a44;content:"";-webkit-transition:.2s;-moz-transition:.2s;-o-transition:.2s;transition:.2s}.border-effect:hover{text-decoration:none;color:#000}.border-effect:hover:after{width:100%}
.imgsigninform{
width:30% ;
}
.imgsignupform{
  width:30% ;

}
.signInPage .box{
  width: 400px; height: 570px; background: #ffffff; margin: auto;  position: relative; z-index: 100; border-radius: 10px !important; margin-top: -10px !important;
}


.signInPage .box h2{font-size: 25px; font-weight: 600;}

.signInPage .box form{padding: 20px 30px; background-color:transparent;
margin-bottom: 0px !important;}

.signInPage .box .form-group{margin-bottom: 15px;} 

.signInPage .box .txt{font-size: 14px; font-weight: bold;}

.loginWithGoogle{width: 100%; font-weight: bold !important; color: rgba(0,0,0,0.8) !important;}
.loginWithGoogle img {width: 30px; margin-right: 10px;}


.signUpPage .box{width: 500px; height: auto !important; background-color: #fff !important; overflow-x: scroll !important;}


.filterTabs .item{display: flex; align-items: center; justify-content: center; padding: 5px 10px !important; text-transform:capitalize; cursor: pointer; transition: all 0.2s;  font-size: 13px; font-weight: 700 !important;}

.loadingOverlay{position: fixed; top: 0px; left: 0px; width: 100%; height:100%; z-index: 10000; cursor: wait; background: rgba(255,255,255,0.7);}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #14274e !important;
}
.css-1aquho2-MuiTabs-indicator{
  background-color: #14274e !important;
}

.snackbar{right: 20px !important; left: inherit !important; z-index: 100000000 !important;}

.paymentPage{padding: 80px 0px;}

.table{
  background-color: #f1f6f9;
}
.myListTableWrapper{width: 70%; margin: auto;}
.myListTable .cartItemimgWrapper{width: 500px !important;}
.myListTable .cartItemimgWrapper .imgWrapper{width: 100px !important; height: 100px !important;}


.checkoutForm .form-group{margin-bottom: 20px;}
.checkoutForm .form-group .MuiOutlinedInput-input{background: #f1f1f1 !important;}

.orderInfo{background: #fafafa !important; padding: 25px !important;}
.orderInfo thead{ border-top: 1px solid rgba(0,0,0,0.1) !important; border-bottom: 1px solid rgba(0,0,0,0.1) !important;}


.tableFooter{padding-bottom: 20px;}
.tableFooter p{margin-bottom: 0px; font-size: 14px; font-weight: 500;}
.tableFooter .pagination{margin-left: auto;}

.tableFooter p{ font-size: 14px; font-weight: 500; margin-bottom: 0px;}

.orderTable th,.orderTable td{white-space: nowrap;}

.orderTable .img{width: 30px;  overflow: hidden;}
.orderTable .img img{width: 100%;}

.myAccountPage .MuiTab-root{font-weight: 600 !important;}

.myAccBox{padding: 25px 0px; background: #fff; border-radius: 10px; }

.userImage{width: 240px; height: 240px; overflow: hidden; border-radius: 100%; border: 2px solid #0858f7; position: relative;}
.userImage img{width: 100%; height: 100%; object-fit: cover;}
.userImage .overlay{width: 100%; height: 100%; position: absolute; top: 0px; left: 0px; background: rgba(0,0,0,0.6); z-index: 1000; cursor: pointer;transition: all 0.2s ease-in-out; opacity: 0;}

.userImage .overlay svg{font-size: 40px; color: #fff;}
.userImage:hover .overlay{opacity: 1;}
.userImage .overlay input{position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 1000; opacity: 0;}


.myAccBox .form-group{margin-bottom: 20px;}
.myAccBox .form-group .MuiOutlinedInput-input{background: #f1f1f1 !important;}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.infinite-scroll-component__outerdiv{width:100% !important;}

.MuiPagination-root button svg{color: #000 !important;}











.payment-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.payment-option {
  display: flex;
  align-items: center;
}

.payment-option label {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.place-order-btn {
  margin-top: 20px;
}

.card-payment-section {
  margin-top: 20px;
}

.card-payment-section h6 {
  margin-bottom: 10px;
  font-size: 18px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-lg {
  padding: 12px 20px;
  font-size: 18px;
}

.bg-red {
  background-color: yellow;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.bg-red:hover {
  background-color: #c82333;
} 


.containerer {
  text-align: center;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  /* background: linear-gradient(to bottom, #3a6d8c, #6a9ab0); */

}

.dua {
  font-size: 24px;
  color: #333;
  border: 2px solid #ccc;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  width: fit-content;
  margin: 0 auto;
  line-height: 1.8;
  font-family: 'Amiri', serif;
}
.containerer img {
  width: 280px;
  margin-bottom: 20px;
}
.containerer h1 {
  font-size: 20px;
  margin-bottom: 10px;
}
.containerer p {
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
}
.containerer button {
  background-color: #007ee5;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
}
.containerer button:hover {
  background-color: #005bb5;
}

.welcomepic{
  width: 300px !important;
}


.main-container-avail{
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to bottom, #3a6d8c, #6a9ab0);

  color: white;
  border-radius: 15px;
  padding: 40px;
  width: 90%;
  flex-direction: column;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  margin-top: 100px;
}

/* .main-container-avail{
  width: 70vw;
  height: 50vh;

} */

.card-contenter {
  max-width: 500px;
}

.card-contenter h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.card-contenter p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 30px;
}

.highlighter {
  color: #142b3a;
  /* color: linear-gradient(to bottom, #142b3a, #143c4e) !important; */

  font-weight: 600;
  font-size: 45px;
}

/* .explore-btner {
  background-color: #4ba4ce;
  color: #000000;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bolder;
  transition: background-color 0.3s ease;
}

.explore-btner:hover {
  background-color: #4aa8d0;
} */




.fonter{
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}



.card-image {
  width: 250px;
  height: 250px;
  background-color: #142b3a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
}


.btnspecialborders {
  background: linear-gradient(to bottom, #3a6d8c, #6a9ab0);
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  color: #ffffff !important;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: 900;
  overflow: hidden;
  transition: 0.2s;
 border: none;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.5);
}

/* .signInPage .box{
  position: relative;
  overflow: hidden;
  transition: 0.2s;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.5);



} */

.btnspecialborders::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 50%;
  background: rgba(255, 255, 255, 0.05);
}

.btnspecialborders  span:nth-child(1) {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #ffe602, #ffa600);

  animation: anim1 2s linear infinite;
}
@keyframes anim1{
  0%{
      transform: translateX(-100%);
  }
  100%{
      transform: translateX(100%);
  }
}

.btnspecialborders  span:nth-child(2) {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 2px;
  height: 100%;
  background: linear-gradient(to right, #ffe602, #ffa600);



  animation: anim2 2s linear infinite;
  animation-delay: 1s;


}

@keyframes anim2{
  0%{
      transform: translateY(-100%);
  }
  100%{
      transform: translateY(100%);
  }
}

.btnspecialborders  span:nth-child(3) {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #ffe602, #ffa600);



  animation: anim3 2s linear infinite;

}
@keyframes anim3{
  0%{
      transform: translateX(100%);
  }
  100%{
      transform: translateX(-100%);
  }
}


.btnspecialborders  span:nth-child(4) {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 2px;
  height: 100%;
  background: linear-gradient(to right, #ffe602, #ffa600);


  animation: anim4 2s linear infinite;
  animation-delay: 1s;

}

@keyframes anim4{
  0%{
      transform: translateY(100%);
  }
  100%{
      transform: translateY(-100%);
  }
}


.btnspecialborders:hover{
  background: #EEEB18 !important;
  color: black !important;
  box-shadow: 0px 25px 50px #EEEB18;
}
.btnspecialborders:hover svg{
  color: black !important;
  transition: 0.3s;
}
.btnspecialborders:hover span{
  background: #EEEB18 !important;
  color: black !important;
}

.btnspecialborderspersonal{
  background: linear-gradient(to bottom, #3a6d8c, #6a9ab0);
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  color: #ffffff !important;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: 900;
  overflow: hidden;
  transition: 0.2s;
 border: none;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.5);
}


.btnspecialborderspersonal::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 50%;
  background: rgba(255, 255, 255, 0.05);
}

.btnspecialborderspersonal  span:nth-child(1) {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to bottom, #142b3a, #143c4e) !important;


  animation: anim1 2s linear infinite;
}
@keyframes anim1{
  0%{
      transform: translateX(-100%);
  }
  100%{
      transform: translateX(100%);
  }
}

.btnspecialborderspersonal  span:nth-child(2) {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 2px;
  height: 100%;
  background: linear-gradient(to bottom, #142b3a, #143c4e) !important;




  animation: anim2 2s linear infinite;
  animation-delay: 1s;


}

@keyframes anim2{
  0%{
      transform: translateY(-100%);
  }
  100%{
      transform: translateY(100%);
  }
}

.btnspecialborderspersonal  span:nth-child(3) {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to bottom, #142b3a, #143c4e) !important;




  animation: anim3 2s linear infinite;

}
@keyframes anim3{
  0%{
      transform: translateX(100%);
  }
  100%{
      transform: translateX(-100%);
  }
}


.btnspecialborderspersonal  span:nth-child(4) {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 2px;
  height: 100%;
  background: linear-gradient(to bottom, #142b3a, #143c4e) !important;



  animation: anim4 2s linear infinite;
  animation-delay: 1s;

}

@keyframes anim4{
  0%{
      transform: translateY(100%);
  }
  100%{
      transform: translateY(-100%);
  }
}


.btnspecialborderspersonal:hover{
  background: linear-gradient(to bottom, #142b3a, #143c4e) !important;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.5);
}

.btnspecialborderspersonal:hover span:nth-child(1){
  background: linear-gradient(to bottom, #142b3a, #143c4e) !important;
  color: black !important;
}
.btnspecialborderspersonal:hover span:nth-child(2){
  background:  linear-gradient(to bottom, #142b3a, #143c4e) !important;
  color: black !important;
}
.btnspecialborderspersonal:hover span:nth-child(3){
  background: linear-gradient(to bottom, #142b3a, #143c4e) !important;
  color: black !important;
}
.btnspecialborderspersonal:hover span:nth-child(4){
  background: linear-gradient(to bottom, #142b3a, #143c4e) !important;
  color: black !important;
}


.content button{
  width: 40%;
  height: 6vh;
  margin: 30px;
  background-color: #142b3a;
  color: white;
  border: none;
  outline: none;
  font-size: 100%;
  font-weight: 700;
  border-radius: 5px;
  transition: 0.2s linear;
}
.content button:hover{
scale: 1.1;
color: rgb(109,67, 0);
border: 2px solid rgb(109,67, 0);
background-color: transparent;
font-weight: 700;
box-shadow: 0 0 40px rgb(109,67, 0);
}

.sectionper{
  width: 100%;
}
.main-containerper{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.main-containerper .image{
  z-index: -1;
}
.main-containerper .image img{
  width: 100%;
}   
.main-containerper .content{
  color: black;
  width: 40%;
  min-height: 100px;
}
.content h1{
  font-size: clamp(1rem, 2rem + 5vw, 3rem);
}
.content h1 span{
  color: black;
}















.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 80px;
  cursor: pointer;
  z-index: 100;
}

.whatsapp-icon img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

/* Shake animation */
@keyframes shake {
  0% { transform: translate(0, 0); }
  25% { transform: translate(-5px, 0); }
  50% { transform: translate(5px, 0); }
  75% { transform: translate(-5px, 0); }
  100% { transform: translate(0, 0); }
}

.shake {
  animation: shake 0.5s;
}











.containerper1 {
  width: 80%;
  margin: 20px auto;
  display: flex;
  gap: 20px;
}

.order-details {
  flex: 2;
}


.order-details .order h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.order-details .order table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.order-details .order table th, .order-details table td {
  text-align: left;
  padding: 8px 10px;
  border-bottom: 1px solid #ddd;
}
.order-details .order table th{
  font-weight: 900;

}

.product-details-showmore {
  width: 100%;
  padding: 10px;

}
.product-details-showmore table{
  width: 500px;
}



.seemorebtn{
  /* background-color: white !important; */
  text-decoration: underline;
  font-size: 13px;
  font-weight: 700;
  position: relative;
  top: 20px;
  cursor: pointer;
  color: rgb(69, 69, 224);

}

.checkedcartitembox{
  cursor: pointer;
}


.badgeper {

  background-color: #ff6f61;
  color: rgb(255, 255, 255);
  padding: 5px 10px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 3px;
  transform: rotate(45deg);
 
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-popular {
  color: rgb(255, 255, 255);
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 900;
  border-radius: 3px;
  transform: rotate(45deg);
 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: -40px;
  width: 150px;
  height: 15px;
  background-color: #f39c12;
}

.badge-new {
  color: rgb(255, 255, 255);
  padding: 5px 10px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 3px;
  transform: rotate(45deg);
 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 70px;
  height: 15px;
  top: 10px;
  right: -15px;
  background-color: #27ae60;
 
}

.bordertable td{
  border: 1px solid rgb(219, 219, 219) !important;

}
.menuliststyle{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
nav .navPart2 ul li a svg {
  color: black;
}
.ordertableinproductname{
  font-size: 13px !important;
  font-weight: 700;
}
.table{
  font-size: 8px !important;
  /* padding: 0.55rem; */
}
.table td {
  padding: .55rem !important;
}









body.dark{background: #000 !important;}
body.dark nav{background: #000 !important;}
body.dark footer{background: #000 !important;}
body.dark .countryDrop .name {
  color: white !important;
}
body.dark header .part3 .circle {
 background: #001f3f !important;
 color: #fff !important;
}
body.dark header .part3 .circle svg{color: white !important; }
body.dark .colorbtnmy {
  background-color: #001f3f !important;
  background: #001f3f !important;
}
body.dark table{
  background: #022e5a !important;

}
body.dark .bordertable {
  background: #001f3f !important;
}
body.dark .bordertable td{
  color: white !important;
}
body.dark .cartPage .table td{
  color: white !important;

}
body.dark .myAccBox{
  background: #001f3f !important;
}
body.dark .cartPage .table tr:hover{
  background: #064c92 !important;

}
body.dark .form-group label{
  color: white !important;
}
body.dark .myAccountPage .MuiTab-root{
  color: white !important;
}
body.dark .content h1 {
  color: white !important;
}
body.dark .content h1 span{
  color: white !important;
}
body.dark .content h4{
  color: white !important;
}
body.dark .cartItemimgWrapper .info h6 {
  color: white !important;
}
body.dark .cartTab .price{
  color: white !important;
}
body.dark .quantityDrop button {
  background: black !important;
}
body.dark .container{background: #000 !important;}

body.dark .countryDrop{color: #ffffff !important; border: 2px solid white !important; }
body.dark .countryDrop .label{color: white !important;}
body.dark .headerWrapper .top-strip{background: #000 !important;}
body.dark .headerSearch input{background: #000 !important; color: white !important; border: 2px solid white !important;  }
body.dark .headerSearch input::-webkit-input-placeholder{color: rgba(255, 255, 255, 0.6);}
body.dark .headerSearch{background: #000 !important; }
body.dark .bg-none{
  background: none !important;
}
body.dark .viewAllBtn{
  background-color: #001f3f !important;
  color: white !important ;
}
body.dark .viewAllBtn svg{
  color: white !important;
}
body.dark footer .linksWrap ul li a {
  color: white !important;
}
body.dark .startmenu .circle svg {
  color: white !important;
}
body.dark footer .linksWrap ul li a:hover{
  color: white !important;
}
body.dark .socials li a svg{
  color: white !important;
}
body.dark .socials li a {
  background-color: #001f3f;
}
body.dark footer .linksWrap h5{
  color: #053c74 !important;
}
body.dark .quantityDrop button{
  background: #001f3f !important;
  color: white !important;
}
body.dark .quantityDrop button svg {
  color: white !important;
}
body.dark .productDetails .btn-circle {
  background: #001f3f !important;
  color: white !important;
}
body.dark .productDetails .btn-circle svg{
  color: white !important;
}
body.dark .cartDetails {
  background: #001f3f !important;
  border: none !important;
}
body.dark .headerWrapper{
  border-bottom: none !important;
}
body.dark .text-red {
  color: rgb(218, 213, 213) !important;
}
body.dark .darkbackground{background: #000 !important; color: white !important; }
body.dark{color: rgba(255,255,255,0.7) !important;}
 body.dark h1,
body.dark h2,
body.dark h3,
body.dark h4,
body.dark h5,
body.dark h6,
body.dark .css-1d3z3hw-MuiOutlinedInput-notchedOutline{border-color: rgba(255,255,255,0.3) !important;}
body.dark .table-responsive::-webkit-scrollbar-thumb{background: rgba(255,255,255,0.3) !important;}
body.dark header{background: #000 !important;}
body.dark .searchBox input,body.dark .searchBox input::-webkit-input-placeholder,body.dark .searchBox svg{color:rgba(255,255,255,0.7) ;}
body.dark .myAcc .userInfo *{color: rgba(255,255,255,0.8) !important;}
body.dark nav .navPart2 ul li a svg {
  color: white;
}


.top-strip {
  background: #110441;
  color: white;
  padding: 10px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.timer {
  display: inline-flex;
  gap: 5px;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  margin-left: 10px;
}

.time-box {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(15, 3, 124);
  color: white;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}

.seconds-box {
  position: relative;
  width: 30px;
  height: 30px;
}

.prev,
.next {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 17px;
  font-weight: bold;
  border-radius: 5px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.next {
  transform: translateY(100%);
  opacity: 0;
}

.slide-in {
  transform: translateY(0);
  opacity: 1;
}

.slide-out {
  transform: translateY(-100%);
  opacity: 0;
}

