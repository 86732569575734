@media only screen and (min-width:320px) and (max-width:992px){
  body,html{overflow-x: hidden !important;}
  body{padding-bottom: 45px !important;}
  .res-hide{display: none !important;}
  
.productItem{
  width: 170px !important;
}
.homeProducts .homeProductsRow .col-md-9{
  padding-left: 0px !important;

}
.whitecolor{
  background: white !important;
  color: black !important;
}
.signupbtn-maindiv{
  padding: 5px 20px;
}
.main-containerper{
align-items: baseline !important;
padding-left: 20px !important;
  margin-top: 0px !important;
  flex-direction: column !important;
}
.main-containerper .content{
  width: auto !important;
}
.main-containerper .content button{
  width: auto !important;
  margin: 0px !important;
}
.product_row {
  display: flex;
  align-items: center;
  width: 100%;
}
.info .netPrice , .info .oldprice {
  font-size:  14px !important;
}
.produtItem{
  width: 50%;
  }
  .userImage{
    width: 100% !important;
    height: auto !important;
  }
  
.userImage img{
  width: 50% !important;
  border-radius: 50%;
}
.whitecolor h4{
  color: black !important;
}
.smallbtnonmobilepersonal{
  width: 50% !important;
  font-size: 14px !important;
  margin-left: 50% !important;

}
.sectionper{
  margin-top: -40px !important;
}
.content h1 {
  font-size: auto !important;
}

.margin-bottom-on-mobile-device{
  margin-bottom: 10px !important;
}

  .containerer {
    text-align: center;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    /* background: linear-gradient(to bottom, #f7dc6f, #a56900); */
  
  }
  
  .dua {
    font-size: 14px;
    padding: 10px !important;
    line-height: 1.8;
    width: 100% !important;
  }
  /* .colorbtnmy{
    font-size: 12px !important;
    width: 180px !important;
    padding: 5px 10px !important;
    margin: 0px !important;
  } */

  .containerer h1 {
    font-size: 15px !important;
  }
  .containerer p {
    font-size: 12px;
  }
  .containerer button {
    color: rgb(0, 0, 0) !important;
    font-size: 12px !important;
  }
 
  
  .containerer img {
    width: 180px !important;
    margin-bottom: 20px;
  }
.allCatTab{
  width: 200px !important;
}

.rowheadermain{
  justify-content: center !important;
}





.main-containerper{
  align-items: baseline !important;
  padding: 0px 10px !important;
    margin-top: 60px !important;
    flex-direction: column !important;
  }
  .main-containerper .content{
    width: auto !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .main-containerper .content address{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .main-containerper .content button{
    width: auto !important;
    padding: 3px 20px;
    margin: 0px !important;
  }
  
  .userImage{
    border: none !important;
    width: 100% !important;
    margin-bottom: 20px;
    height: auto !important;
  }
  
  .content h4{
  padding: 10px 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  
  }
  .none-display{
    display: block !important;
  }
  .main-containerper{
  height: auto !important;
  }
  .userImage img{
  width: 50% !important;
  border-radius: 50%;
  }



  .main-container-avail{
    margin-top: 10px !important;
  }
  .btnspecialborders{
    font-size: 12px !important;
    margin-left: 0px !important;
  }
  .card-image{
    margin-left: 0px !important;
    visibility: hidden;
  }

  .card-contenter {
    max-width: 500px;
  }
  
  .card-contenter h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  
  .card-contenter p {
    font-size: 12px;
    margin-bottom: 30px;
  }
  
  .highlighter {
    color: black;
    font-weight: 600;
    font-size: 20px;
  }
.banner-container{
  height: 200px !important;
}
  



  .headerWrapper{border-bottom: 0px}

  .container{max-width: 100% !important ;}

  .res-full{width: 100% !important; max-width: 100% !important; flex: 0 0 100% !important; 
    display: none !important;
  }

  .homeProducts{
    padding: 5px 15px !important;
  }
/* .checkedcartitembox{
  display: flex;
  width: 100% !important;
  height: 100% !important;
  align-items: center;
  justify-content: center;
} */
  .headerWrapper .header{padding: 10px 0px;}
  /* .headerWrapper .header .logoWrapper{width: 60px !important; background-image: url("./assets/images/whitelogo.png"); background-size: 100% 100%;} */
  .headerWrapper .header .logoWrapper{width: 60px !important; padding: 0px;}
  .headerWrapper .header .part2{width: 71% !important; padding: 0px !important; }
  /* .headerWrapper .header .part2 .btn-blue{padding: 5px 10px !important; margin-right: 10px !important;} */
.homeBannerSection img{
  height: 20vh !important;
}
.viewAllBtn{
  width: 100px !important;
  font-size: 12px !important;
  padding: 3px !important;
  border-radius: 10px !important;
  position: relative !important;
  top: -15px !important;
}
.newsLetterSection form button{
  padding: 2px 15px !important;
  top: 5px !important;
}
  /* .headerWrapperFixed.fixed{top: 0px !important;} */
  
  /* .headerSearchWrapper{position: fixed; top: 0px; left: 0px; width: 100% !important; height: 100vh; background: #fff; z-index: 1000; padding: 15px !important; margin: 0px !important; opacity: 0; visibility: hidden; transition: all 0.3s;} */
.mobilesearchbox{
  width: 70% !important;
  margin-left: 0px !important;
 
}


  .closeSearch{display: block; font-size: 20px;}
.whatsapp-icon{
  position: fixed;
  right: 5px;
  bottom: 20px;
}

  .headerSearch{
    position: relative;
   margin: 0px !important;  background-color: white !important;  padding-left: 0px !important;
   margin-left: 10px !important;

  display: flex;
  align-items: center;
  justify-content: center;
  } 


.headerSearch input{
  width: 90%;
  position: relative;
  font-size: 10px;
  height: 30px !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important; border-radius: 5px; padding-left: 10px !important;
}
  .res-nav{position: fixed; left: -100%;
    display: flex;
    flex-direction: column;
    bottom:0%; width: 60%; height: 90vh; background: #fff; z-index: 1011; padding: 0px 0px; transition: all 0.2s ease-in-out; opacity: 0; overflow-y: scroll;}
.logoutfixed{
  position: fixed !important;
  bottom: 0px !important;
  right: 0px;
  width: 60% !important;
  margin: 1px;

}
.frontpartoful{
  background: rgb(1, 39, 56);
  width: 100%;
  height: 90px;
  color: white;
  margin: 0px;
  padding: 0px;
}

.sidebarmaindiv{
display: flex;
align-items: center;
gap: 10px;
}
.emailinnavbar{
  font-size: 10px;
}
.sidenavmainimg{
  min-width: 30%;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px ;
  border: 1px solid black;
  overflow: hidden;
}
.useremailmain{
    font-size: 10px !important;
}
.sidenavmainimg img{
  width: 100% !important;
  height: 100% !important;
}
.sidebarusercontent{
padding: 3px;
}
.sidebarusercontent p{
  font-weight: bold;
  
margin: 0;
padding: 0;
}



  .res-nav .btn-blue{border-radius: 5px !important; text-align: center !important; justify-content: center !important;}



  .res-nav-overlay{width: 100%; height: 100%; position: fixed; top: 0px; left: 0px; background: rgba(0,0,0,0.7); z-index: 100;  opacity: 0; visibility: hidden;}

  .res-nav-wrapper.open .res-nav{right: 0px; opacity: 1; transition: all 0.6s linear;}
  .res-nav-wrapper.open .res-nav-overlay{opacity: 1; visibility: inherit;}

  .res-nav li{width: 100%; padding-bottom: 0px !important;}
  .res-nav li a,  .res-nav li a button{display: flex !important; width: 100% !important; border-radius: 0px !important; justify-content: flex-start !important;}

  nav .navPart2 ul li .submenu{width: 100% !important; position: static !important; height: 0px; box-shadow: none !important; transition: all 0.3s;}
  nav .navPart2 ul li .submenu.open{ height: auto !important; opacity: 1 !important; visibility: inherit !important;}

  nav .navPart2 ul li .arrow{display: flex; align-items: center; justify-content: center;
    position: absolute; top: 7px; right: 20px; z-index: 100; transition: all 0.3s ease-in-out;
    width: 30px;
    height: 30px;
    background: #f1f1f1;
    border-radius: 100%;}
    nav .navPart2 ul li .arrow.rotate{transform: rotate(180deg);}
    nav .navPart1{
      display: none;
    }

/* 
  nav .navPart1 .catWrapper .sidebarNav{opacity: 1 !important; visibility: inherit !important; position: relative !important; top: 0px !important; left: 0px !important; height: auto !important; display: block !important; border: 0px !important; padding: 0px !important;}

  nav .navPart1{max-width: 100% !important; width: 100% !important;}

  nav .navPart1 .catWrapper .sidebarNav ul{display: block !important; width: 100% !important; white-space: nowrap !important; overflow: scroll !important; overflow-y:hidden !important ;
  margin-bottom: 0px !important;}
  nav .navPart1 .catWrapper .sidebarNav ul::-webkit-scrollbar{display: none !important;}


  nav .navPart1 .catWrapper .sidebarNav ul li{display: inline-block !important; vertical-align: top !important; width: max-content !important; padding: 0px !important;} */
  .banner-img{
    height: 120px !important;
    border-radius: 15px !important;
  }
  .part3{
    width: 40% !important;
  }
  .top-strip{
    font-size: 12px;
    padding: 0px !important;
  }
  .time-box{
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
  .seconds-box{
    width: 20px !important;
    height: 20px !important;
    font-size: 10px !important;
  }
  .prev,
.next{
  font-size: 12px !important;
}
  .headerWrapperFixed + div{margin-top: 20px !important;}
.logoWrapper img{
/* display: none !important; */
width: 100% !important;
}
.hd{
  margin-bottom: 25px !important;
}

.res-full{
  display: none;
}
  .homeProducts .homeProductsRow .col-md-3{order: 2; max-width: 100% !important; flex: 0 0 100% !important;}
  .homeProducts .homeProductsRow .col-md-9{order: 1; padding-right: 0px !important; max-width: 100% !important; flex: 0 0 100% !important;}

  .productRow .info{width: 100% !important;}
  .productRow div.ml-auto{width: 100% !important;}

  .res-flex-column{flex-direction: column;}

  .product_row .swiper{padding-right: 0px !important;}
  
  .bannerSec{white-space: nowrap !important; overflow: scroll !important; overflow-y: hidden;
  display: block !important;}
  .bannerSec::-webkit-scrollbar{display: none !important;}
  .bannerSec .banner{width: 220px !important; display: inline-block !important; margin-right: 10px !important;}


  .zoomSliderBig .item{
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 0px !important;

  }

  .homeProductsRow .sticky{position: static !important; display: block !important; white-space: nowrap !important; overflow: scroll !important; overflow-y: hidden !important;}
  .homeProductsRow .sticky .banner{display: none !important; vertical-align: top !important; width: 150px !important; margin-right: 15px !important; margin-top: 0px !important;}

  .homeProductsRow .sticky::-webkit-scrollbar{display: none !important;}

  .newsLetterSection{padding: 25px 0px !important;}
  .newsLetterSection img{display: none !important;}

  footer{overflow: hidden; padding: 0px !important; height: auto !important;}
  footer .topInfo{ display: none !important; white-space: nowrap !important; overflow: scroll !important; overflow-y: hidden !important;}
  footer .topInfo::-webkit-scrollbar{display: none !important;}
  footer .topInfo .col{width: 250px !important; max-width: 250px !important; flex: 0 0 250px !important; display: inline-block !important;}

  footer .linksWrap{display: block !important; white-space: nowrap !important; overflow: scroll !important; overflow-y: hidden !important;}
  footer .linksWrap::-webkit-scrollbar{display: none !important;}
  footer .linksWrap .col{width: 200px !important; max-width: 200px !important; flex: 0 0 200px !important; display: inline-block !important;}
  .quantityDrop{width: 54% !important; gap: 5px !important;}

.OrderPage{
  margin-top: 40px !important;
}
.productDetails{
  margin-top: 40px !important;
}
.CheckoutPage{
  margin-top: 0px !important;
}
.cartPage{
  margin-top: 100px !important;
}
  .product_row{margin: 0px !important;}
  .productScroller{white-space: nowrap !important; overflow: scroll; overflow-y: hidden; display: block !important; width: 100% !important; transform: inherit !important; padding: 15px 0px;}
  .productScroller::-webkit-scrollbar{display: none !important;}
  .productScroller .productItem{width: 170px !important; float: none !important; display: inline-block !important; vertical-align: top; margin-right: 10px !important; padding: 0px !important; border-radius: 0% !important;}

  .product_Listing_Page{padding-top: 15px !important;}
  .product_Listing_Page .productListing .sidebar{position: fixed; top: 0px !important; right: -100%; width: 100%; height: 100%; max-height: 100vh; overflow-y: scroll; z-index: 10000; background: #fff; flex: 0 0 100% !important; padding: 25px; transition: all 0.3s ease-in-out; opacity: 0;}
  .product_Listing_Page .productListing .sidebar.open{right: 0px !important; opacity: 1 !important;}
  .product_Listing_Page .productListing .sidebar img{display: none !important;}

  .product_Listing_Page .productListing .content_right{width: 100% !important; flex:0 0 100% !important; padding-left: 0px;}

  .fixedBtn{position: fixed; bottom: 0px; left: 0px; width: 100%; height: auto; padding: 15px;
  z-index: 10000; background: #fff; margin: auto !important;}
  .fixedBtn .col button{width: 100% !important;}

  .productDetails .part1{padding-left: 15px !important;}
  .productDetails .part2{padding:20px 15px !important;}
  .productDetails .part2 .hd{font-size: 22px !important; line-height: 30px !important;}
  .productDetails .actions_{ display: flex; align-items: center;}
.productZoom .badge{
  top: 7px !important;
  left: 121px !important;
}
body.dark .navPart2 ul{
  background-color: black !important;
  color: white !important;

}
body.dark nav .navPart2 ul li a button {
 
  color: white !important;

}
.changestyleorderbtn{
  font-size: 15px !important;
  padding: 5px 18px !important;
}
body.dark nav .navPart2 ul li .submenu a button {
  background-color: black !important;
}
body.dark nav .navPart2 ul li .arrow {
background: black !important;
color: white !important;
}
  .btnActions{ position: relative;  width: 100% !important;}

  .detailsPageTabs{margin-top: 0px !important;}

  .signInPage{height: auto !important;}
  .signInPage .box{width:100% !important ;}
  .signInPage .box form{padding: 20px 10px !important;}
  .signInPage .box form button{margin-bottom: 10px;}

  .cartDetails{margin-top: 15px;}
  .myListTableWrapper{width: 100% !important;}
  .myListTableWrapper .col-md-12{padding-right: 15px !important;}

  table th{white-space: nowrap !important;}

  .myAccBox .col-md-8{margin-top: 25px !important;}

  .linksWrap{margin-top: 0px !important; padding: 0px 10px;}

  .productModal .MuiPaper-elevation{width: 100% !important; max-width: 900px !important;
  margin: 10px !important;}





  footer .linksWrap h5 {
    margin-bottom: 5px !important;
  }
  footer .linksWrap ul li{
    margin-bottom: 5px !important;
  }
  .copyright{
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
    .tabContent table *{white-space: nowrap;}
  
    .productItem .info h4{white-space: initial !important; }
   .productItem .info{
    padding: 8px !important;
  }
    .cartPage .col-md-9.pr-5{padding-right: 15px !important;}
  
    .homeCat .swiper .item{display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;}
  
  
  
      .bannerSection{white-space: nowrap !important; overflow: scroll !important; overflow-y: hidden !important; display: block !important; padding-top: 0px !important;}    
      .bannerSection::-webkit-scrollbar{display: none !important;}
      .bannerSection .col_{ width: 280px !important; margin-right: 10px !important;}
  
      .productScroller + div.mt-4{margin-top: 0px !important;}

}








    


  

@media only screen and (min-width:320px) and (max-width:350px){
  .homeCat .swiper .item{height: 130px;}
  


}
@media only screen and (min-width:0px) and (max-width:420px){
  .headerWrapper .header .part2{width: 80% !important; padding: 0px !important; flex-wrap: nowrap;}
  .headerWrapper .header .logoWrapper{
    width: 15vw !important;
  }
  .header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }


}

@media only screen and (min-width:320px) and (max-width:575px){
  .thankuh1{
    font-size: 15px;
    margin-bottom: 20px;
  }
  .productSize{padding-bottom: 0px !important;}

  .detailsPageTabs{padding: 20px !important;}

  .customTabs ul{white-space: nowrap !important; overflow: scroll !important; overflow-y: hidden; display: block !important; margin-bottom: 0px !important;}
   .customTabs ul::-webkit-scrollbar{display: none !important;}
  .customTabs ul li{display: inline-block !important; width: max-content !important;}
  .customTabs ul li button{padding: 5px 15px !important; font-size: 13px !important;}

  .productDetaileModal .actions_{flex-direction: column !important; padding: 10px 0px; justify-content: flex-start !important;}

  .productDetaileModal .actions_ .quantityDrop {margin-bottom: 15px !important;}

  .productDetaileModal .actions_ .btn-big{margin-right: auto !important; margin-left: 0px !important;}
  
  .productModal .actions{margin-top: 15px !important;}
  .productModal .actions button{zoom: 80%;}

  .countryDrop{width: 100% !important;}
  .homeCat .swiper .item{width: 60px !important; height: 60px !important; border-radius: 0% !important;}

}


@media only screen and (min-width:320px) and (max-width:350px){
  .productListing, .infinite-scroll-component{
    gap:7px !important;
  }
  .headerWrapper .header .logoWrapper img{
    margin-left: -20px !important;

  }
  .product_Listing_Page .productItem{width: 100% !important;}
  .productListing .productItem.one{width: 100% !important;}

  /* .productItem .img_rapper{height: 205px !important;} */
}

@media only screen and (min-width:350px) and (max-width:600px){
  body,html{overflow-x: hidden;}

  .productListing, .infinite-scroll-component{
    gap:7px !important;
  }
  .product_Listing_Page .productListing{gap: 7px !important;}
  .product_Listing_Page .productItem{width: 48% !important;}
  .productListing .productItem.one{width: 100% !important;}
  /* .productItem .img_rapper{height: 205px !important;} */
  /* .card-contenter {
    max-width: 500px;
  }
  
  .card-contenter h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  
  .card-contenter p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .highlighter {
    color: black;
    font-weight: 600;
    font-size: 25px;
  }
  .explore-btner {
    background: linear-gradient(to bottom, #f7dc6f, #a56900);
    margin-top: 20px;
margin-left: 30px;

  
  } */
  

}

@media only screen and (min-width:600px) and (max-width:767px){
  body,html{overflow-x: hidden;}

  .productListing, .infinite-scroll-component{
    gap:7px !important;
  }
  .product_Listing_Page .productItem{width:32.5% !important;}
  .productListing .productItem.one{width: 100% !important;}
}

@media only screen and (min-width:767px) and (max-width:992px){
  .productListing, .infinite-scroll-component{
    gap:7px !important;
  }
  .product_Listing_Page .productItem{width:23% !important;}
  .productListing .productItem.one{width: 100% !important;}

  
}

@media only screen and (min-width:320px) and (max-width:395px){
  .quantityDrop{
    gap: 0px !important;
  }
  .btn-big{
    padding: 8px 18px !important ; 
  }
}



@media only screen and (min-width:320px) and (max-width:767px){
  body,html{overflow-x: hidden;}

  header .part3 .circle{min-width: 35px !important; width: 35px !important; height: 35px !important; margin-right: 8px !important; border-radius: 0% !important;}
  .cartTab .position-relative.ml-2{margin-left: 0px !important;}  

  #accDrop ul li{padding: 5px 12px !important; font-size: 14px !important;}


  .newsLetterSection h3{font-size: 30px !important;}
  .newsLetterSection p br{display: none !important;}
  .newsLetterSection .text-white {
    font-size: 18px !important;
  }
  .newsLetterSection form{width: 80% !important; height: 40px !important; margin-top: 0px !important; padding: 0px !important;}
  .newsLetterSection form svg{
    top: 10px !important;
  }

  .newsLetterSection button{
    color: rgb(255, 255, 255) !important;
    margin-left: 10px;
  }
  .responsiveform{
    display: flex ;
    align-items: center;
  }
  .newsLetterSection form input {
    padding-right: 0px !important;
  }
  .copyright {flex-direction: column; justify-content: center;}
  .copyright *{text-align: center !important; margin: auto !important;}
  .copyright ul{padding-top: 10px !important;}
  .copyright ul li{margin: 0px 5px !important;}

  .empty h3{text-align: center; font-size: 18px !important;}
  

}

@media only screen and (min-width:575px) and (max-width:767px){
  body,html{overflow-x: hidden;}

  .productRow2{padding: 20px 0px !important;}
  .productRow2 .productItem{width:31% !important;}
  .productSize{padding-bottom: 0px !important;}
  .productItem .img_rapper img{height: 100% !important; object-fit: cover !important;}
 
}

@media only screen and (min-width:662px) and (max-width:1200px){
  .allCatTab{
    font-size: 10px !important;
  }
  body,html{overflow-x: hidden;}

  
}

