* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

body {
    background-color: #f5f5f5;
    color: #333;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: #fff;
}

.menu-icon {
    font-size: 24px;
    cursor: pointer;
}

nav ul {
    list-style: none;
    display: flex;
    
}

nav ul li {
    margin: 10px 0;
}

nav ul li a {
    text-decoration: none;
    color: #333;
    font-size: 14px;
    text-transform: uppercase;
}

.language-selector {
    margin-top: 10px;
}

.language-selector a {
    text-decoration: none;
    color: #666;
    font-size: 12px;
}

.logo {
    font-size: 24px;
    font-weight: bold;
}

.social-icons img {
    width: 20px;
    margin-left: 10px;
}

main {
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets//images//4k\ wal.jpg'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
}

.content {
    text-align: left;
    color: #000;
}

.content h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.content h1 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
}

.countdown {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.timer {
    text-align: center;
}

.timer .number {
    font-size: 36px;
    font-weight: bold;
}

.timer .label {
    font-size: 12px;
    text-transform: uppercase;
}

.buttons {
    display: flex;
    gap: 10px;
}

.btn {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
}

.btn-primary {
    background-color: #000;
    color: #fff;
}

.btn-secondary {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
}

footer {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    color: #666;
    font-size: 14px;
}