.banner-container {
  width: 80vw !important;
  margin: auto;
  position: relative;
  height: 320px ;
}

.banner-container .swiper-container {
  overflow: visible !important; 
}

.banner-container .swiper-slide {
  opacity: 0.4;
  transform: scale(0.8) !important; 
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.banner-container .swiper-slide-active {
  opacity: 1 !important;
  transform: scale(1.1) !important;  
}

.banner-container .banner-img {
  border-radius: 50px ;
  width: 100%;
  height: 270px ;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.swiper-button-next,
.swiper-button-prev {
  color: black !important;
  background: rgba(255, 255, 255, 0.7) !important;
  padding: 10px !important;
  border-radius: 50% !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: white !important;
}

.swiper-pagination-bullet {
  position: relative;
  top: 60px;
  background: black !important;
  opacity: 0.5 !important;
}

.swiper-pagination-bullet-active {
  background: rgb(36, 5, 71) !important;
  opacity: 1 !important;
}